<template>
  <div class="global-dropdown account-type-dropdown">
    <div style="padding: 0px 16px">
      <span class="span-label-organization-request">
        <a
          href="#"
          @click.prevent="$emit('click')"
          class="clickable-link"
          style="color: #473068 !important"
        >
          I'm unable to find the business
        </a>
      </span>
    </div>
    <span
      v-for="registry in globalRegistryList"
      class="brand-name"
      :key="'registry-' + registry.id"
      @click="clickOrganizationOption(registry.id)"
    >
      <span :class="`organization-${registry.type_details.short_code}-label`">
        {{ registry.type_details.name }}
      </span>
      <span>{{ registry.name }}</span>
      <md-option
        :id="`organization-option-${registry.id}`"
        style="display: none"
        :value="registry.id"
        >{{ registry.name }}
      </md-option></span
    >
    <infinite-loading
      @infinite="handleInfinite"
      :key="`infinite-toggle-${searchName}`"
    >
      <div slot="no-more" v-if="globalRegistryList.length">No more data</div>
      <div slot="no-more" v-if="!globalRegistryList.length">
        No data available
      </div>
      <div slot="no-results" v-if="globalRegistryList.length">No more data</div>
      <div slot="no-results" v-if="!globalRegistryList.length">
        No data available
      </div>
    </infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";

export default {
  name: "GlobalRegistryDropdown",
  components: {
    InfiniteLoading,
  },
  props: {
    globalRegistryList: {
      type: Array,
      required: true,
    },
    searchName: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleInfinite($state) {
      this.$emit("fetch-more", $state);
    },
    clickOrganizationOption(id) {
      document.getElementById("organization-option-" + id).click();
    },
  },
};
</script>

<style lang="scss" scoped>
.span-label-organization-request {
  font-size: 12px;
  cursor: pointer !important;
  color: #473068 !important;
}
.global-dropdown {
  overflow-y: scroll;
  max-height: 270px;
  text-align: left;
}
.account-type-dropdown {
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 3.19039px 14.8885px 12.7616px rgba(55, 78, 138, 0.1);
  border-radius: 4px;
}
.account-type-dropdown::v-deep .md-menu-item .md-list-item-button:hover {
  background: $backgroundColor;
}
.md-select {
  height: 40.8px;
  overflow: unset;
}
.md-select::v-deep .md-input {
  padding-bottom: 12px;
}
.md-select::v-deep .md-input,
.md-select::v-deep .md-textarea {
  font-weight: 500 !important;
  font-size: 13.9971px;
  // line-height: 16px;
  letter-spacing: 2px;
}
.account-type-dropdown::v-deep .md-selected .md-list-item-content,
.account-type-dropdown::v-deep .router-link-active .md-list-item-content {
  color: $infoMessage;
  background: rgba(243, 244, 248, 0.6);
}
.account-type-dropdown::v-deep .md-list-item-content {
  color: $primaryDarkest;
}
.account-type-dropdown::v-deep .md-list-item-content:hover {
  color: $infoMessage;
}
.md-select::v-deep .md-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $primaryDarkest;
  opacity: 1; /* Firefox */
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 16px;
  letter-spacing: 2px;
}
::v-deep .v-menu__content {
  position: fixed;
  display: unset;
}
::v-deep .v-menu {
  display: unset;
}
.brand-name {
  display: block;
  white-space: normal;
  padding: 4px 16px;
  color: $primaryDarkest;
  cursor: pointer;
  margin: 5px 0px;
  word-wrap: anywhere;
}
.brand-name:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.organization-supplier-label {
  background-color: #5b3d87;
  color: white;
  border-radius: 50px;
  padding: 2px 10px;
  font-size: 12px;
  text-align: center;
  margin-right: 5px;
}
.organization-brand-label {
  @extend .organization-supplier-label;
  background-color: #70d0ce;
  padding: 2px 16.11px;
}
</style>
<style>
.md-menu-content.md-select-menu {
  width: unset !important;
}
.md-menu-content {
  max-width: unset;
}
</style>
